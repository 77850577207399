<template>
  <div
    class="bg-white px-8 quotation-detail-page"
    style="height: calc(100vh - 50px)"
  >
    <v-row v-if="pageLoaded">
      <v-col md="12" class="py-0">
        <v-row>
          <v-col md="4" class="d-flex align-center justify-start flex-wrap">
            <v-chip
              v-if="
                dbQuotation.barcode &&
                (dbQuotation.status == 16 || dbQuotation.status == 8)
              "
              label
              outlined
              color="blue darken-4"
              class="font-weight-600 mr-2"
              >{{ dbQuotation.barcode }}</v-chip
            >
            <v-chip
              v-else-if="dbQuotation.barcode && dbQuotation.status != 8"
              label
              outlined
              color="cyan"
              class="font-weight-600 mr-2"
              >{{ dbQuotation.barcode }}</v-chip
            >
            <v-chip
              label
              :color="dbQuotation?.status_relation?.color"
              :text-color="dbQuotation?.status_relation?.textcolor"
              class="mr-2 text-uppercase font-weight-600"
              >{{ dbQuotation?.status_relation?.text }}</v-chip
            >
            <v-chip
              v-if="dbQuotation?.is_contract == 1 && dbQuotation?.status == 2"
              label
              color="green darken-4"
              text-color="white"
              class="mr-2 text-uppercase font-weight-600"
              >Converted to Contract</v-chip
            >
            <v-chip
              v-if="dbQuotation?.invoice_converted == 1"
              label
              color="green"
              text-color="white"
              class="mr-2 text-uppercase font-weight-600"
              >Converted to Invoice</v-chip
            >
            <v-chip
              v-if="false && dbQuotation?.visit_converted == 1"
              label
              color="orange"
              text-color="white"
              class="mr-2 text-uppercase font-weight-600"
              >Converted to Visit</v-chip
            >
            <v-chip
              v-if="dbQuotation?.type == 1"
              label
              color="light-green darken-3"
              text-color="white"
              class="mr-2 text-uppercase font-weight-600"
              >New</v-chip
            >
            <v-chip
              v-if="dbQuotation?.type == 2"
              label
              color="orange darken-4 white--text"
              text-color="white"
              class="mr-2 text-uppercase font-weight-600"
              >Maintenance</v-chip
            >
            <v-chip
              label
              color="blue"
              class="mr-2 text-uppercase font-weight-600"
              v-if="dbQuotation?.revise"
              outlined
              ><!-- {{ data?.revise_relation?.barcode }} -->
              Revision</v-chip
            >
            <v-chip
              v-if="dbQuotation?.type == 2"
              label
              :color="maintenanceType.color"
              text-color="white"
              class="mr-2 text-uppercase font-weight-600"
              >{{ maintenanceType.text }}</v-chip
            >
            <v-chip
              v-if="dbQuotation.is_signed == 1"
              label
              color="red darken-4 white--text"
              text-color="white"
              class="mr-2 text-uppercase font-weight-600"
              >Signed</v-chip
            >
            <template v-if="dbQuotation?.has_tool == 1">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="green darken-4"
                    class="mr-2"
                    >mdi-tools</v-icon
                  >
                </template>
                <span>Tools</span>
              </v-tooltip>
            </template>
          </v-col>
          <v-col md="8" class="d-flex align-center justify-end flex-wrap">
            <template v-if="!dbQuotation?.is_revised">
              <template
                v-if="
                  dbQuotation?.is_internal_approved &&
                  dbQuotation?.status == 8 &&
                  !dbQuotation?.is_customer_approved
                "
              >
                <v-btn
                  v-on:click="updateMoreAction('approved_by_customer')"
                  depressed
                  color="green white--text"
                  class="mx-1 custom-bold-button"
                  ><v-icon small left>mdi-check-all</v-icon>Accepted by
                  Customer</v-btn
                >
                <v-btn
                  v-on:click="updateMoreAction('rejected_by_customer')"
                  depressed
                  color="red white--text"
                  class="mx-1 custom-bold-button"
                  ><v-icon small left>mdi-close</v-icon>Rejected by
                  Customer</v-btn
                >
              </template>
              <v-btn
                v-if="
                  dbQuotation?.can_send_sdo_mail &&
                  dbQuotation?.type == 1 &&
                  dbQuotation?.status != 12 &&
                  dbQuotation?.status != 17
                "
                v-on:click="updateMoreAction('send-sdo')"
                :disabled="sendingSDO"
                :loading="sendingSDO"
                depressed
                color="cyan white--text"
                class="mx-1 custom-bold-button"
                ><v-icon small left>mdi-send</v-icon>Send SDO (Workshop)
              </v-btn>
              <v-btn
                v-if="dbQuotation?.status == 1 || dbQuotation?.status == 12"
                v-on:click="updateMoreAction('edit')"
                depressed
                color="cyan mx-1 white--text"
                class="custom-bold-button"
                ><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
              >
            </template>
            <v-btn
              v-if="dbQuotation?.status > 1"
              v-on:click="updateMoreAction('revise')"
              depressed
              color="cyan"
              class="custom-bold-button mx-2 text-white"
              ><v-icon small left>mdi-update</v-icon> Revise</v-btn
            >
            <template v-if="!dbQuotation?.is_revised">
              <template
                v-if="
                  dbQuotation?.more_actions && dbQuotation?.more_actions.length
                "
              >
                <template v-if="dbQuotation?.more_actions.length > 2">
                  <v-menu
                    v-if="
                      dbQuotation?.more_actions &&
                      dbQuotation?.more_actions.length
                    "
                    transition="slide-y-transition"
                    bottom
                    content-class="custom-menu-list"
                    offset-y
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        depressed
                        color="cyan white--text"
                        class="custom-bold-button mx-2"
                        >More...
                        <v-icon small right>mdi-chevron-down</v-icon></v-btn
                      >
                    </template>
                    <v-list>
                      <template
                        v-for="(more, index) in dbQuotation?.more_actions"
                      >
                        <v-list-item
                          link
                          v-on:click="updateMoreAction(more.action)"
                          :key="index"
                          :disabled="more.disabled"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-1 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <template v-else>
                  <template v-for="(more, index) in dbQuotation?.more_actions">
                    <v-btn
                      v-on:click="updateMoreAction(more.action)"
                      :key="index"
                      :disabled="more.disabled"
                      depressed
                      :color="more.color"
                      class="custom-bold-button mx-1 text-white"
                      ><v-icon small left>{{ more.icon }}</v-icon>
                      {{ more.title }}</v-btn
                    >
                  </template>
                </template>
              </template>

              <template v-if="!dbQuotation?.is_internal_approved && canApprove">
                <v-btn
                  v-on:click="updateMoreAction('mark_as_approved')"
                  depressed
                  color="green"
                  class="custom-bold-button mx-1 text-white"
                  ><v-icon small left>mdi-check-all</v-icon> Mark as
                  Approved</v-btn
                >
                <v-btn
                  v-on:click="updateMoreAction('mark_as_diapproved')"
                  depressed
                  color="red"
                  class="custom-bold-button mx-1 text-white"
                  ><v-icon small left>mdi-close</v-icon> Mark as
                  Dis-Approved</v-btn
                >
              </template>
            </template>
            <v-btn v-on:click="goBack()" depressed class="custom-bold-button"
              ><v-icon small left>mdi-chevron-left</v-icon>Back</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col md="5">
        <v-row>
          <v-col md="12">
            <span
              class="cursor-pointer color-custom-blue font-weight-700 font-size-19"
            >
              <router-link
                :to="
                  getDefaultRoute('customer.detail', {
                    params: {
                      id: dbQuotation?.customer_relation?.id,
                    },
                  })
                "
                >{{ dbQuotation?.customer_relation?.display_name }}
              </router-link>
            </span>
          </v-col>
          <v-col md="6">
            <label class="my-0">Billing Location</label>
            <template v-if="dbQuotation?.billing_relation?.id">
              <p class="mb-0 text-capitalize">
                <template v-if="dbQuotation?.billing_relation?.property_name">
                  {{ dbQuotation?.billing_relation?.property_name }}<br />
                </template>

                <template v-if="dbQuotation?.billing_relation.unit_no"
                  >{{ dbQuotation?.billing_relation.unit_no }},</template
                >
                <template v-if="dbQuotation?.billing_relation.street_1"
                  >{{ dbQuotation?.billing_relation.street_1 }},</template
                >
                {{ dbQuotation?.billing_relation.street_2 }}
                <br />

                <template
                  v-if="
                    dbQuotation?.billing_relation.zip_code &&
                    dbQuotation?.billing_relation.zip_code != '000000' &&
                    dbQuotation?.billing_relation.zip_code != 'nil' &&
                    dbQuotation?.billing_relation.zip_code != 'NIL' &&
                    dbQuotation?.billing_relation.zip_code != 'Nil'
                  "
                >
                  {{ dbQuotation?.billing_relation.country }}
                  {{ dbQuotation?.billing_relation.zip_code }}
                </template>
              </p>
            </template>
            <label class="mt-3">Contact Details</label>
            <template v-if="dbQuotation?.billing_contact_person_relation?.id">
              <p class="mb-0">
                {{ dbQuotation?.billing_contact_person_relation?.display_name }}
              </p>
              <p class="mb-0">
                {{
                  dbQuotation?.billing_contact_person_relation?.primary_phone
                }}
              </p>
              <p class="mb-0 d-flex align-center">
                {{
                  dbQuotation?.billing_contact_person_relation?.primary_email
                }}
              </p>
            </template>
          </v-col>
          <v-col md="6">
            <label class="my-0 text-capitalize">Site Info</label>
            <template v-if="dbQuotation?.property_relation?.id">
              <p class="mb-0">
                <template v-if="dbQuotation?.property_relation?.property_name">
                  {{ dbQuotation?.property_relation?.property_name }}<br />
                </template>
                <template v-if="dbQuotation?.property_relation?.unit_no"
                  >{{ dbQuotation?.property_relation?.unit_no }},</template
                >
                <template v-if="dbQuotation?.property_relation?.street_1"
                  >{{ dbQuotation?.property_relation?.street_1 }},</template
                >
                {{ dbQuotation?.property_relation?.street_2 }}
                <br />

                <template
                  v-if="
                    dbQuotation?.property_relation.zip_code &&
                    dbQuotation?.property_relation.zip_code != '000000' &&
                    dbQuotation?.property_relation.zip_code != 'nil' &&
                    dbQuotation?.property_relation.zip_code != 'NIL' &&
                    dbQuotation?.property_relation.zip_code != 'Nil'
                  "
                >
                  {{ dbQuotation?.property_relation.country }}
                  {{ dbQuotation?.property_relation.zip_code }}
                </template>
              </p>
            </template>
            <label class="mt-3">Contact Details</label>
            <template v-if="dbQuotation?.property_contact_person_relation?.id">
              <p class="mb-0">
                {{
                  dbQuotation?.property_contact_person_relation?.display_name
                }}
              </p>
              <p class="mb-0">
                {{
                  dbQuotation?.property_contact_person_relation?.primary_phone
                }}
              </p>
              <p class="mb-0 d-flex align-center">
                {{
                  dbQuotation?.property_contact_person_relation?.primary_email
                }}
              </p>
            </template>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="7" class="gray-background">
        <table width="100%">
          <tr>
            <td
              colspan="2"
              class="color-custom-blue font-weight-700 font-size-19"
            >
              {{ dbQuotation?.title }}
            </td>
          </tr>
          <!-- <tr>
            <td class="font-weight-600" width="20%">Rate opportunity</td>
            <td class="py-0 my-0">
              <v-rating
                readonly
                class="my-0 py-0"
                v-model.trim="dbQuotation.rating"
                background-color="orange lighten-3"
                color="orange"
              ></v-rating>
            </td>
          </tr> -->
          <tr>
            <td class="font-weight-600" width="20%">Date</td>
            <td class="font-weight-600">{{ formatDate(dbQuotation?.date) }}</td>
          </tr>
          <tr v-if="dbQuotation?.contract_relation?.id">
            <td class="font-weight-600" width="20%">Contract #</td>
            <td
              class="font-weight-600 cursor-pointer"
              style="color: #24326d !important"
              v-on:click="routeToContract()"
            >
              {{ dbQuotation?.contract_relation?.barcode }} -
              {{ dbQuotation?.contract_relation?.title }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Sale by</td>
            <td class="font-weight-600">
              {{ dbQuotation?.sale_by_relation?.display_name }} ({{
                dbQuotation?.sale_by_relation?.user_email
              }})
            </td>
          </tr>
          <tr v-if="dbQuotation?.enquiry_relation">
            <td class="font-weight-600" width="20%">Enquiry</td>
            <td
              class="font-weight-600 cursor-pointer color-custom-blue"
              v-on:click="routeToEnquiry()"
            >
              {{ dbQuotation?.enquiry_relation?.barcode }} -
              {{ dbQuotation?.enquiry_relation?.title }}
            </td>
          </tr>
          <tr v-if="false">
            <td class="font-weight-600" width="20%">Customer ref</td>
            <td class="font-weight-600">
              <template v-if="dbQuotation?.customer_ref">
                {{ dbQuotation?.customer_ref }}
              </template>
              <em v-else class="text-muted">no customer ref</em>
            </td>
          </tr>
          <tr v-if="false">
            <td class="font-weight-600" width="20%">Your ref</td>

            <td class="font-weight-600">
              <template v-if="dbQuotation?.your_ref">
                {{ dbQuotation?.your_ref }}
              </template>
              <em v-else class="text-muted">no Your ref</em>
            </td>
          </tr>
          <tr v-if="dbQuotation?.approver?.id">
            <td class="font-weight-600" width="20%">Approver</td>
            <td class="font-weight-600">
              {{ dbQuotation?.approver?.display_name }} ({{
                dbQuotation?.approver?.user_email
              }})
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Quote by</td>
            <td class="font-weight-600">
              {{ dbQuotation?.quote_by_relation?.display_name }} ({{
                dbQuotation?.quote_by_relation?.user_email
              }})
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Quote Amount</td>
            <td class="font-weight-600">
              {{ formatMoney(dbQuotation?.total) }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Visit Type</td>
            <td class="font-weight-600">
              <template v-if="dbQuotation?.visit_type">{{
                dbQuotation?.visit_type
              }}</template>
              <em v-else class="text-muted">no Visit Type</em>
            </td>
          </tr>
          <template v-if="dbQuotation?.type == 1 && dbQuotation?.can_send_sdo_mail">
            <tr>
              <td class="font-weight-600" width="20%">SDO PDF</td>
              <td class="font-weight-600">
                <v-btn
                  icon
                  depressed
                  small
                  color="red"
                  :disabled="!dbQuotation?.sdo_pdf_url"
                  v-on:click="downloadFile(dbQuotation?.sdo_pdf_url)"
                >
                  <v-icon>mdi-file-pdf-box</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template v-if="dbQuotation?.type == 2">
            <tr v-if="dbQuotation?.contract_start && dbQuotation?.contract_end">
              <td class="font-weight-600" width="20%">Commencement Period</td>
              <td class="font-weight-600">
                This Contract will start on
                {{ formatDate(dbQuotation.contract_start) }} and finish on
                {{ formatDate(dbQuotation.contract_end) }}
              </td>
            </tr>
            <tr v-if="dbQuotation?.duration_statement">
              <td class="font-weight-600" width="20%"></td>
              <td class="font-weight-600">
                {{ dbQuotation.duration_statement }}
              </td>
            </tr>
          </template>
          <tr v-if="false">
            <td class="font-weight-600" width="20%">No. of Contract Days</td>
            <td class="font-weight-600">
              {{ contractSchedule.length }}
            </td>
          </tr>
        </table>
      </v-col>
      <v-col md="12">
        <v-tabs
          active-class="custom-tab-active"
          v-model="quotationTab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent tab-sticky"
        >
          <v-tab class="font-size-14 font-weight-600 px-8" href="#overview">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/quotation.svg')" />
              <!--end::Svg Icon-->
            </span>
            Overview
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#line-item">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/line-item.svg')" />
              <!--end::Svg Icon-->
            </span>
            Line Items
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#invoice">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/invoice.svg')" />
              <!--end::Svg Icon-->
            </span>
            Invoices
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#visit">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/visit.svg')" />
              <!--end::Svg Icon-->
            </span>
            Visits
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#history">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/history.svg')" />
              <!--end::Svg Icon-->
            </span>
            History
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#attachment">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/attachment.svg')" />
              <!--end::Svg Icon-->
            </span>
            Attachments
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#document">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/attachment.svg')" />
              <!--end::Svg Icon-->
            </span>
            Documents
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="quotationTab" class="custom-border-top">
          <v-tab-item value="overview">
            <v-expansion-panels mandatory accordion>
              <v-expansion-panel v-if="dbQuotation?.is_contract == 1">
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      CONTRACT
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <div class="w-100">
                    <table width="80%">
                      <tbody>
                        <tr>
                          <td class="font-weight-600" width="10%">
                            Start Date
                          </td>
                          <td class="font-weight-600" width="40%">
                            {{ formatDate(dbQuotation?.contract_start_date) }}
                          </td>
                          <td class="font-weight-600" width="10%">End Date</td>
                          <td class="font-weight-600" width="40%">
                            {{ formatDate(dbQuotation?.contract_end_date) }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-600">Schedule</td>
                          <td class="font-weight-600" colspan="3">
                            {{ dbQuotation?.contract_message }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <v-btn
                      v-if="
                        dbQuotation?.is_contract &&
                        maintenanceType?.value != 'onetime'
                      "
                      depressed
                      small
                      v-on:click="scheduleDialog = true"
                      color="orange darken-4 white--text"
                      class="mt-4 ml-2 custom-bold-button"
                      >Show Schedule</v-btn
                    >
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      DESCRIPTION
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <div class="w-100">
                    <div
                      v-if="dbQuotation?.description"
                      v-html="dbQuotation?.description"
                    />
                    <div
                      v-else
                      class="text--secondary"
                      style="font-style: italic"
                    >
                      no description
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      Notes:
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <div v-if="dbQuotation.type == 1" class="w-100">
                    <div class="mb-2 d-flex">
                      <div class="mr-1">(1)</div>
                      <div>
                        Prices quoted above are for jobs carried out during
                        working hours, 0800 hrs to 1730hrs (Mondays to Fridays),
                        and 0800 hrs to 1200hrs (Saturdays). A 50% surcharge
                        will apply for after normal workings hours. A 100%
                        surcharge will apply for Sundays and Public Holidays.
                      </div>
                    </div>
                    <div class="mb-2 d-flex">
                      <div class="mr-1 min-custom-w">(2) Payment Terms</div>
                      <div class="mr-1">
                        {{ dbQuotation?.payemnt_term }}
                      </div>
                    </div>

                    <div class="mb-2 d-flex">
                      <div class="mr-1 min-custom-w">(3) Lead - time</div>
                      <div class="mr-1">
                        {{ dbQuotation?.lead_time }}
                      </div>
                    </div>

                    <div class="mb-2 d-flex">
                      <div class="mr-1 min-custom-w">
                        (4) Quotation Validity
                      </div>
                      <div class="mr-1">7 days from the date hereof.</div>
                    </div>

                    <div class="mb-2 d-flex">
                      <div class="mr-1 min-custom-w">(5) Warranty</div>
                      <div class="mr-1">{{ dbQuotation?.warranty_remark }}</div>
                    </div>

                    <div class="mb-2 d-flex">
                      <div class="mr-1 min-custom-w">(6) Exclusions</div>

                      <div class="mr-1">{{ dbQuotation?.exclusions }}</div>
                    </div>
                    <div class="mb-2 d-flex">
                      <div class="mr-1 min-custom-w">(7) Remarks</div>
                      <div class="mr-1">{{ dbQuotation?.remark_list }}</div>
                    </div>

                    <div class="mb-2">
                      If you are agreeable with the above quotation, please
                      revert as soon as possible by faxing us your
                      works/purchase order so that we can proceed with
                      above-mentioned works.
                    </div>
                  </div>
                  <div v-else class="w-100">
                    <table>
                      <tr>
                        <td class="font-weight-600">Date of Commencement</td>
                        <th width="5px">:</th>
                        <td class="font-weight-600">
                          {{
                            formatCustomDate(
                              dbQuotation.contract_start,
                              "DD MMMM YYYY"
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-600">Date of Expiry</td>
                        <th>:</th>
                        <td class="font-weight-600">
                          {{
                            formatCustomDate(
                              dbQuotation.contract_end,
                              "DD MMMM YYYY"
                            )
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-600">Contract Type</td>
                        <th>:</th>
                        <td class="font-weight-600">
                          {{ termConditionText }}, in accordance with the
                          attached Terms & Conditions.
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-600" colspan="3">
                          {{ dbQuotation?.remark_1 }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-600" colspan="3">
                          {{ dbQuotation?.remark_2 }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-600" colspan="3">
                          {{ dbQuotation?.remark_3 }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      TERMS &amp; CONDITIONS
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <div class="w-100">
                    <div
                      class="tnc-viewer"
                      v-if="dbQuotation?.term_conditions"
                      v-html="dbQuotation.term_conditions"
                    ></div>
                    <div
                      v-else
                      class="text--secondary"
                      style="font-style: italic"
                    >
                      no terms &amp; conditions
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="dbQuotation.status != 1">
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <template v-if="dbQuotation.status == 8">
                      <h3
                        class="font-weight-600 custom-headline color-custom-blue font-size-14"
                      >
                        APPROVED REMARK
                      </h3>
                    </template>
                    <template v-if="dbQuotation.status == 12">
                      <h3
                        class="font-weight-600 custom-headline color-custom-blue font-size-14"
                      >
                        DIS-APPROVED REMARK
                      </h3>
                    </template>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <div class="w-100">
                    <template v-if="dbQuotation.status == 8">
                      <div
                        v-if="dbQuotation?.accepted_remarks"
                        v-html="dbQuotation?.accepted_remarks"
                      />
                      <div
                        v-else
                        class="text--secondary"
                        style="font-style: italic"
                      >
                        no remark
                      </div>
                    </template>

                    <template v-if="dbQuotation.status == 12">
                      <div
                        v-if="dbQuotation?.approved_reject_remarks"
                        v-html="dbQuotation?.approved_reject_remarks"
                      />
                      <div
                        v-else
                        class="text--secondary"
                        style="font-style: italic"
                      >
                        no remark
                      </div>
                    </template>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      INTERNAL NOTES &amp; ATTACHMENTS
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <v-row>
                    <v-col md="6" class="custom-border-right">
                      <label class="font-size-16 font-weight-600"
                        >Internal Note</label
                      >
                      <div class="w-100 pl-1 pt-2 custom-border-top-1px">
                        <div
                          v-if="dbQuotation?.admin_note"
                          v-html="dbQuotation?.admin_note"
                        />
                        <div
                          v-else
                          class="text--secondary"
                          style="font-style: italic"
                        >
                          no internal note
                        </div>
                      </div>
                    </v-col>
                    <v-col md="6" class="custom-border-left">
                      <label class="font-size-16 font-weight-600"
                        >Client Note
                        <span class="text--secondary font-weight-500"
                          >(visible on PDF)</span
                        ></label
                      >
                      <div class="w-100 pl-1 pt-2 custom-border-top-1px">
                        <div
                          v-if="dbQuotation?.client_note"
                          v-html="dbQuotation?.client_note"
                        />
                        <div
                          v-else
                          class="text--secondary"
                          style="font-style: italic"
                        >
                          no client note
                        </div>
                      </div>
                    </v-col>
                    <v-col md="12" class="custom-border-top mt-4">
                      <v-row>
                        <v-col md="6">
                          <label class="font-size-16 font-weight-600"
                            >Attachments ({{
                              dbQuotation.attachments.length
                            }})</label
                          >
                          <table width="100%" class="custom-border-top-1px">
                            <tbody>
                              <tr
                                v-for="(row, index) in dbQuotation.attachments"
                                :key="`view-attach-${index}`"
                              >
                                <td
                                  valign="middle"
                                  width="80%"
                                  class="pr-2 py-2 text-capitalize"
                                >
                                  {{ row.name }}

                                  <v-chip
                                    label
                                    small
                                    color="blue"
                                    class="ml-2"
                                    outlined
                                    text-color=""
                                  >
                                    <template v-if="row.type == 2">
                                      Internal Use</template
                                    >
                                    <template v-if="row.type == 31">
                                      Approved</template
                                    >
                                  </v-chip>
                                </td>
                                <td
                                  valign="middle"
                                  width="20%"
                                  align="right"
                                  class="pl-2 py-2"
                                >
                                  <v-icon
                                    color="cyan"
                                    class="mr-2"
                                    v-on:click="downloadFile(row?.file?.url)"
                                    >mdi-download</v-icon
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-tab-item>
          <v-tab-item value="line-item">
            <line-item-view is-quotation :quote-type="dbQuotation?.type ?? 1" />
          </v-tab-item>
          <v-tab-item value="invoice">
            <internal-invoice type="quotation" :type-id="dbQuotation?.id" />
          </v-tab-item>
          <v-tab-item value="visit">
            <internal-visit
              :customer-id="dbQuotation?.customer"
              :property-id="dbQuotation?.property"
              :quotation-id="dbQuotation?.id"
              detailType="tickets"
              visitType="all"
              internal
              :visit-status="0"
              detailRoute="visit.detail"
            />
          </v-tab-item>
          <v-tab-item value="history">
            <internal-history-detail
              type="quotation"
              :type_id="dbQuotation?.id"
            />
          </v-tab-item>
          <v-tab-item value="attachment">
            <CommonAttachment type-text="Attachments" type="quotation" :type-id="dbQuotation?.id"/>
          </v-tab-item>
          <v-tab-item value="document">
            <div>
              <v-layout
                class="p-4 pr-0 border-bottom-light-grey min-height-57px"
              >
                <v-flex class="d-flex font-level-3-bold my-auto">
                  <span class="detail-svg-icon mr-2">
                    <!--begin::Svg Icon-->
                    <span class="svg-icon svg-icon-lg mr-2 svg-v-list-icon">
                      <inline-svg
                        :src="$assetURL('media/custom-svg/attachment.svg')"
                      />
                    </span>
                    <!--end::Svg Icon-->
                  </span>
                  <h1
                    class="form-title font-size-20 font-weight-700 text-capitalize"
                  >
                    Documents
                  </h1>
                </v-flex>
                <v-flex class="text-right">
                  <v-btn
                    v-on:click="attachmentDialog = true"
                    color="cyan text-white"
                    class="ml-2"
                    depressed
                    tile
                    ><v-icon small left>mdi-playlist-plus</v-icon>Attach File
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-row>
                <v-col
                  v-for="(row, index) in dbAttachments"
                  :key="index"
                  class="border-bottom d-flex"
                  :class="{
                    'custom-border-top': index == 0,
                    'orange_lighten-5': index % 2 == 0,
                  }"
                  md="12"
                >
                  <div class="px-4" style="width: 60px">
                    <template v-if="row.extension && isImage(row.extension)">
                      <ImageTemplate
                        :src="row.url"
                        style="max-width: 50px; width: 50px"
                      ></ImageTemplate>
                    </template>
                    <template v-else>
                      <inline-svg
                        style="max-width: 50px; width: 50px"
                        :src="$assetURL(`media/mime/${row.extension}.svg`)"
                      />
                    </template>
                  </div>
                  <div style="width: 70%" class="px-4 text-truncate">
                    <b
                      class="blue--text text--darken-4 cursor-pointer"
                      v-on:click="doAction(row, 'download')"
                    >
                      {{ row?.name }}
                    </b>
                    <div>
                      <b>Size :</b
                      >{{ Number(Number(row.size) / 1024).toFixed(2) }}KB
                    </div>
                    <div>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            label
                            small
                            :color="row.action_color"
                            class="text-uppercase"
                          >
                            {{ row.action }}</v-chip
                          >
                        </template>
                        <span>when status is</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <div style="width: 20%" class="px-4 d-flex flex-wrap">
                    <div class="mr-0">
                      <div>
                        <b class="d-inline-block" style="width: 80px"
                          >Created At</b
                        >:&nbsp;&nbsp;
                        <span v-if="row.added_at">
                          {{ formatDateTime(row.added_at) }}
                        </span>
                        <em class="text-muted" v-else> no created </em>
                      </div>
                      <div>
                        <b class="d-inline-block" style="width: 80px"
                          >Created by </b
                        >:&nbsp;&nbsp;
                        <span v-if="row.added_by.display_name">
                          {{ row.added_by.display_name }}
                        </span>
                        <em class="text-muted" v-else> no Created by </em>
                      </div>
                    </div>
                  </div>
                  <div style="width: 10%" class="px-4 d-flex">
                    <v-spacer></v-spacer>
                    <v-btn
                      v-on:click="doAction(row, 'download')"
                      small
                      icon
                      depressed
                      color="blue darken-4"
                      class="mx-1"
                    >
                      <v-icon medium>mdi-download</v-icon>
                    </v-btn>
                    <v-btn
                      :disabled="dbAttachments.length < 2"
                      v-on:click="doAction(row, 'delete')"
                      small
                      icon
                      depressed
                      color="red "
                      class="ml-2"
                    >
                      <v-icon medium>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col md="12">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p class="text-center font-weight-600 font-size-19">Please Wait...</p>
        <v-progress-linear rounded indeterminate height="8" color="orange" />
      </v-col>
    </v-row>
    <confirm-dialog
      key="confirm-dialog-quotation"
      :common-dialog="confirmDialog"
      :dialog-width="dialogWidth"
    >
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-container class="px-10" fluid>
          <p
            class="font-weight-600 font-size-19"
            v-if="confirmType != 'mark_as_send'"
          >
            Are you sure, you want to
            <span class="font-weight-700">{{ getConfirmTitle() }}</span
            >?
          </p>
          <template v-else>
            <p class="font-weight-600 font-size-19">
              Are you sure you want to
              <span class="font-weight-700"
                >send this draft quotation to the Approver</span
              >?
            </p>
            <div class="d-flex align-center w-100">
              <div class="w-30">
                <label for="quotation-approver" class="font-weight-600"
                  >Select Approver</label
                >
              </div>
              <div class="w-70">
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  id="quotation-approver"
                  label="Approvers"
                  solo
                  flat
                  class="mb-2"
                  item-color="cyan"
                  hide-details
                  :disabled="confirmLoading"
                  :loading="confirmLoading"
                  :items="approversList"
                  v-model="confirmApproverId"
                />
              </div>
            </div>
          </template>
          <v-layout
            class="my-4"
            v-if="
              [
                'mark_as_approved',
                'mark_as_rejected',
                'mark_as_accepted',
                'mark_as_diapproved',
                'approved_by_customer',
                'rejected_by_customer',
              ].includes(confirmType)
            "
          >
            <v-flex>
              <label class="font-weight-500 font-size-16">Remark</label>
              <TextAreaField
                auto-grow
                dense
                filled
                color="cyan"
                v-model.trim="actionData.remark"
                placeholder="Remark"
                solo
                flat
                row-height="25"
                counter="250"
              />
            </v-flex>
          </v-layout>
          <template
            v-if="
              confirmType == 'mark_as_accepted' ||
              confirmType == 'mark_as_approved' ||
              confirmType == 'approved_by_customer' ||
              confirmType == 'rejected_by_customer'
            "
          >
            <v-layout class="mt-4">
              <v-flex md12>
                <label class="font-weight-500 font-size-16"
                  >Attachments (Max 5)</label
                >
                <table width="100%">
                  <tbody>
                    <tr
                      v-for="(row, index) in actionData.attachments"
                      :key="index"
                    >
                      <td width="50%" class="pl-0 pr-2 py-2">
                        <v-file-input
                          prepend-inner-icon="mdi-attachment mdi-rotate-90"
                          placeholder="Click here to select file"
                          label="Click here to select file"
                          prepend-icon=""
                          color="cyan"
                          solo
                          flat
                          v-model="row.accepted_file"
                          dense
                          v-on:change="updateFileName(index)"
                          hide-details
                        ></v-file-input>
                      </td>
                      <td width="50%" class="pr-0 pl-2 py-2">
                        <v-text-field
                          placeholder="File Name"
                          label="File Name"
                          dense
                          solo
                          flat
                          color="cyan"
                          v-model="row.file_name"
                          hide-details
                          class="has-delete-outer-icon"
                          append-outer-icon="mdi-delete"
                          v-on:click:append-outer="removeRow(index)"
                          :suffix="getFileExtension(row.file_type)"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr v-if="actionData.attachments.length < 5">
                      <td colspan="2" class="pl-0">
                        <v-btn
                          color="cyan white--text"
                          small
                          v-on:click="addAttachmentRow()"
                          class="custom-bold-button"
                          >Add More...</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-flex>
            </v-layout>
            <v-layout v-if="dbQuotation?.type == 2" class="my-4">
              <v-flex md12>
                <p class="my-0 red--text font-weight-600">
                  Note: Once you save and accept, a Contract will be generated
                  with a draft status.
                </p>
              </v-flex>
            </v-layout>
          </template>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="confirmLoading"
          v-on:click="confirmDialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          No! Close
        </v-btn>
        <v-btn
          v-on:click="confirmSubmit()"
          :disabled="confirmLoading"
          :loading="confirmLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          Yes! Continue
        </v-btn>
      </template>
    </confirm-dialog>
    <confirm-dialog
      key="show-schedule-dialog-quotation"
      :common-dialog="scheduleDialog"
      :dialog-width="dialogWidth"
    >
      <template v-slot:title>
        Contract Schedule ({{ contractSchedule.length }})</template
      >
      <template v-slot:body>
        <v-container fluid>
          <table width="100%">
            <tr>
              <th class="text-center">S.No.</th>
              <th>Date</th>
              <th>Day</th>
              <th>Status</th>
            </tr>
            <tr
              v-for="(row, index) in contractSchedule"
              :key="index"
              :class="{ 'visit-row-hover': row.is_edited }"
            >
              <td
                class="font-size-14 font-weight-500"
                align="center"
                valign="middle"
              >
                {{ index + 1 }}.
              </td>
              <td class="font-size-14 font-weight-500">
                {{ formatDate(row.start_date) }}
              </td>
              <td class="font-size-14 font-weight-500">
                {{ row.days_of_week }}
              </td>
              <td>
                <v-icon color="green lighten-1" v-if="row.status == 1"
                  >mdi-check</v-icon
                >
                <v-icon color="red lighten-1" v-else>mdi-close</v-icon>
              </td>
            </tr>
          </table>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          v-on:click="scheduleDialog = false"
          depressed
          class="custom-grey-border custom-bold-button"
        >
          Close
        </v-btn>
      </template>
    </confirm-dialog>
    <send-mail
      :mail-dialog="mailDialog"
      hide-signature
      title="Send as Email"
      type="quotation"
      v-on:close="mailDialog = false"
      :type-id="quotationId"
      v-on:success="getQuotation()"
    ></send-mail>    
    <DocumentwithName
      :document-dialog="attachmentDialog"
      v-on:close="attachmentDialog = false"
      :document-type="31"
      :document-id="dbQuotation?.id"
      module="quotation"
      :module-id="dbQuotation?.id"
      module-action="Accepted by Customer"
      v-on:success="getAttachments()"
    ></DocumentwithName>
    <delete-dialog :commonDialog="deleteDialog" :dialog-width="600">
      <template v-slot:title> Delete File</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/attention-circle.svg')"
              />
              <!--end::Svg Icon-->
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p my-0">
              Are you sure you want to delete,
              <span class="font-weight-600">{{
                deleteRow?.name ? deleteRow?.name : deleteRow?.file?.name
              }}</span>
              ?
            </p>
            <br />
            <span>Note: Deleting File is irreversible</span>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text"
          :loading="deleteLoading"
          :disabled="deleteLoading"
          depressed
          color="red lighten-1"
          tile
          v-on:click="deleteFile()"
        >
          Yes! Delete
        </v-btn>
        <v-btn
          depressed
          tile
          :disabled="deleteLoading"
          v-on:click="deleteDialog = false"
        >
          No, Close
        </v-btn>
      </template>
    </delete-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import { SET_ERROR } from "@/core/services/store/common.module";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import JwtService from "@/core/services/jwt.service";
import { UPLOAD, PATCH, GET, DELETE } from "@/core/services/store/request.module";
import {
  SET_VIEW_LINE_ITEM,
  SET_VIEW_CALCULATION,
} from "@/core/services/store/line.item.module";
import LineItemViewV2 from "@/view/pages/partials/Line-Item-View-V2.vue";
import DocumentwithName from "@/view/pages/DocumentwithName";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import Dialog from "@/view/pages/partials/Dialog";
import ObjectPath from "object-path";
import SendMail from "@/view/pages/partials/Send-Mail";
import InternalInvoice from "@/view/pages/partials/Internal-Invoice";
import InternalVisit from "@/view/pages/visit/Visit-Listing-Template";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { NotificationReloadEventBus } from "@/core/lib/notification.reload.lib";
import CommonAttachment from "@/view/components/Attachment.vue";
import ImageTemplate from "@/view/pages/Image";

/*import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);*/

export default {
  mixins: [CommonMixin],
  data() {
    return {
      canApprove: false,
      formLoading: false,
      approversList: [],
      dbAttachments: [],
      confirmApproverId: null,
      tab: null,
      quotationTab: "line-item",
      dbQuotation: {},
      contractSchedule: [],
      quotationId: 0,
      pageLoaded: false,
      mailDialog: false,
      confirmDialog: false,
      confirmLoading: false,
      scheduleDialog: false,
      sendingSDO: false,
      attachmentDialog: false,
      confirmType: null,
      termConditionText: null,
      actionData: {
        remark: null,
        status: null,
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      },
      pageLoading: true,
      extensionArray: ["jpg", "png", "gif", "jpeg", "webp", "tiff", "jfif"],
      deleteLoading: false,
      deleteRow: null,
      deleteDialog: false,
    };
  },
  methods: {
    doAction(row, param) {
      console.log(row, param);
      switch (param) {
        case "download":
          window.open(row.url, "_blank");
          break;
        case "delete":
          this.deleteRow = row;
          this.deleteDialog = true;
          break;
      }
    },    
    deleteFile() {
      if (!this.deleteRow?.id) {
        return false;
      }

      if(this.dbAttachments.length < 2){
        return false;
      }

      this.deleteLoading = true;
      this.$store
        .dispatch(DELETE, {
          url: `delete-documents/${this.deleteRow.id}`,
        })
        .then(() => {
          this.deleteRow = null;
          this.deleteDialog = false;
          this.getAttachments();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },
    downloadFile(url, prepend_url) {
      if (!url) {
        return false;
      }
      if (prepend_url) {
        url = `https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/${url}`;
      }
      window.open(url, "_blank");
    },
    routeToEnquiry() {
      this.$router.push(
        this.getDefaultRoute("enquiry.detail", {
          params: { id: this.dbQuotation?.enquiry },
        })
      );
    },
    routeToContract() {
      this.$router.push(
        this.getDefaultRoute("contract.detail", {
          params: { id: this.dbQuotation?.contract_relation?.id },
        })
      );
    },
    confirmSubmit() {
      /* if (
        this.actionData &&
        !this.actionData?.remark &&
        this.confirmType != "mark_as_send"
      ) {
        ErrorEventBus.$emit("update:error", "Remark filed is required.");
        return false;
      } */

      if (this.confirmType === "approved_by_customer") {
        let status = false;
        for (let i = 0; i < this.actionData.attachments.length; i++) {
          if (this.actionData.attachments[i].accepted_file) {
            status = true;
          }
        }

        if (!status) {
          ErrorEventBus.$emit(
            "update:error",
            "A minimum of one attachment is required."
          );
          return false;
        }
      }

      switch (this.confirmType) {
        case "mark_as_closed":
          this.updateStatus("mark_as_closed");
          break;
        case "mark_as_cancelled":
          this.updateStatus("mark_as_cancelled");
          break;
        case "mark_as_pending":
          this.updateStatus("mark_as_pending");
          break;
        case "mark_as_rejected":
          this.updateStatus("mark_as_rejected");
          break;
        case "mark_as_accepted":
          this.updateStatus("mark_as_accepted");
          break;
        case "mark_as_approved":
          this.updateStatus("mark_as_approved");
          break;
        case "mark_as_diapproved":
          this.updateStatus("mark_as_diapproved");
          break;
        case "approved_by_customer":
          this.updateStatus("approved_by_customer");
          break;
        case "rejected_by_customer":
          this.updateStatus("rejected_by_customer");
          break;
        case "mark_as_send":
          this.updateStatus("mark_as_send");
          break;
      }
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.actionData.attachments.length; i++) {
          if (_this.actionData.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.actionData.attachments[i].accepted_file,
              _this.actionData.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    async updateStatus() {
      if (this.confirmLoading) {
        return false;
      }

      try {
        this.confirmLoading = true;

        this.actionData.approver_id = this.confirmApproverId;
        this.actionData.status = this.confirmType;
        this.actionData.attachments = await this.uploadFiles();

        this.$store
          .dispatch(PATCH, {
            url: "quotation/" + this.quotationId + "/status",
            data: this.actionData,
          })
          .then(() => {
            this.getQuotation();
            this.resetConfirm();
            NotificationReloadEventBus.$emit("notification:reload");
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.confirmLoading = false;
          });
      } catch (error) {
        this.logError(error);
      } finally {
        this.confirmLoading = false;
      }
    },
    resetConfirm() {
      this.confirmDialog = false;
      this.confirmType = null;
      this.actionData = {
        remark: null,
        status: null,
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      };
    },
    updateFileName(index) {
      const file = this.actionData.attachments[index].accepted_file;
      if (file) {
        this.actionData.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.actionData.attachments[index].file_type = file.name
          .split(".")
          .pop();
        this.addAttachmentRow();
      }
    },
    removeRow(index) {
      ObjectPath.del(this.actionData.attachments, index);
      this.$nextTick(() => {
        if (!this.actionData.attachments.length) {
          this.addAttachmentRow();
        }
      });
    },
    addAttachmentRow() {
      if (this.actionData.attachments.length < 5) {
        this.actionData.attachments.push({
          accepted_file: null,
          file_name: null,
          file_type: null,
        });
      }
    },
    getFileExtension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    getConfirmTitle() {
      if (this.confirmType == "mark_as_closed") {
        return "Mark as Lost";
      } else if (this.confirmType == "mark_as_cancelled") {
        return "Mark as Cancelled";
      } else if (this.confirmType == "mark_as_pending") {
        return "Mark as Pending";
      } else if (this.confirmType == "mark_as_rejected") {
        return "Mark as Rejected";
      } else if (this.confirmType == "mark_as_accepted") {
        return "Mark as Accepted";
      } else if (this.confirmType == "mark_as_approved") {
        return "Mark as Approved";
      } else if (this.confirmType == "mark_as_send") {
        return "Mark as Send";
      } else if (this.confirmType == "mark_as_diapproved") {
        return "Mark as Dis-Approved";
      } else if (this.confirmType == "approved_by_customer") {
        return "Mark as Accepted by Customer";
      } else if (this.confirmType == "rejected_by_customer") {
        return "Mark as Rejected by Customer";
      }
    },
    routeToInvoice() {
      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: { quotation: this.dbQuotation?.id },
        })
      );
    },
    routeToJob() {
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: {
            quotation: this.dbQuotation?.id,
            "quote-type": this.dbQuotation?.type,
          },
        })
      );
    },
    routeToDuplicate() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: { duplicate: this.dbQuotation?.id },
        })
      );
    },
    editQuotation() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: { edit: this.dbQuotation?.id },
        })
      );
    },
    reviseQuotation() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: { revise: this.dbQuotation?.id },
        })
      );
    },
    getSignatureLink() {
      this.$store
        .dispatch(GET, {
          url: "quotation/" + this.quotationId + "/signature-link",
        })
        .then(({ data }) => {
          const { signature_link } = data;
          window.open(signature_link, "_blank");
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    updateMoreAction(action) {
      this.resetConfirm();
      switch (action) {
        case "edit":
          this.editQuotation();
          break;
        case "revise":
          this.reviseQuotation();
          break;
        case "convert_visit":
          this.routeToJob();
          break;
        case "signature_link":
          this.getSignatureLink();
          break;
        case "convert_invoice":
          this.routeToInvoice();
          break;
        case "duplicate":
          this.routeToDuplicate();
          break;
        case "approved_by_customer":
          this.confirmDialog = true;
          this.confirmType = "approved_by_customer";
          break;
        case "rejected_by_customer":
          this.confirmDialog = true;
          this.confirmType = "rejected_by_customer";
          break;
        case "mark_as_closed":
          this.confirmDialog = true;
          this.confirmType = "mark_as_closed";
          break;
        case "mark_as_cancelled":
          this.confirmDialog = true;
          this.confirmType = "mark_as_cancelled";
          break;
        case "send_as_email":
          this.mailDialog = true;
          break;
        case "send-sdo":
          this.mailDialog = true;
          // this.sendSDOMail();
          break;
        case "mark_as_pending":
          this.confirmDialog = true;
          this.confirmType = "mark_as_pending";
          break;
        case "mark_as_rejected":
          this.confirmDialog = true;
          this.confirmType = "mark_as_rejected";
          break;
        case "mark_as_diapproved":
          this.confirmDialog = true;
          this.confirmType = "mark_as_diapproved";
          break;
        case "mark_as_accepted":
          this.confirmDialog = true;
          this.confirmType = "mark_as_accepted";
          break;
        case "mark_as_send":
          this.confirmDialog = true;
          this.confirmType = "mark_as_send";
          break;
        case "mark_as_approved":
          this.confirmDialog = true;
          this.confirmType = "mark_as_approved";
          break;
        case "download_pdf":
          this.downloadPDF();
          break;
        case "print":
          this.printPDF();
          break;
      }
    },
    sendSDOMail() {
      this.sendingSDO = true;
      this.$store
        .dispatch(PATCH, { url: `quotation-v1/${this.quotationId}/sdo-mail` })
        .then(() => {
          setTimeout(() => {
            this.sendingSDO = false;
          }, 2000);
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    isImage(ext) {
      if (find(this.extensionArray, (row) => row == ext.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    downloadPDF() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation-v1/${this.quotationId}/pdf?type=contract&token=${token}&action=download`;
      window.open(url, "_blank");
    },
    printPDF() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation-v1/${this.quotationId}/pdf?type=contract&token=${token}&action=print`;
      window.open(url, "_blank");
    },
    getQuotation() {
      this.$store
        .dispatch(GET, { url: "quotation-v1/" + this.quotationId })
        .then(({ data }) => {
          this.approversList = data.approvers;
          this.canApprove = data.can_approve;
          this.contractSchedule = data.contract_schedule;
          delete data.contract_schedule;
          this.dbQuotation = data;
          this.termConditionText = data.term_condition_text;

          this.getAttachments();

          this.$store.dispatch(SET_VIEW_CALCULATION, {
            additional_rows: this.dbQuotation?.additional_rows ?? [],
            ctx_discount_label: this.dbQuotation?.ctx_discount_label ?? null,
            ctx_discount_value: this.dbQuotation?.ctx_discount_value ?? 0,
            ctx_discount: this.dbQuotation?.ctx_discount ?? 0,
            ctx_discount_type: this.dbQuotation?.ctx_discount_type ?? 1,
            sub_total: this.dbQuotation?.sub_total ?? 1,
            discount_type: this.dbQuotation?.discount_type ?? 1,
            discount_value: this.dbQuotation?.discount_value ?? 0,
            discount_amount: this.dbQuotation?.discount_amount ?? 0,
            tax_active: this.dbQuotation?.tax_active ?? false,
            tax_value: this.dbQuotation?.tax_value ?? 0,
            tax_amount: this.dbQuotation?.tax_amount ?? 0,
            adjustment: this.dbQuotation?.adjustment ?? 0,
            total: this.dbQuotation?.total ?? 0,
            show_price: true,
            discount_level: this.dbQuotation?.discount_level ?? "transaction",
          });
          this.$store.dispatch(SET_VIEW_LINE_ITEM, data.line_item);
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Quotation", route: "quotation" },
            { title: "Detail" },
            { title: data.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
          this.pageLoading = false;
        });
    },
    getAttachments() {
      this.$store
        .dispatch(GET, {
          url: "quotation/" + this.quotationId + "/attachments",
        })
        .then(({ data }) => {
          this.dbAttachments = data;
        })
        .catch((error) => {
          this.logError(error);
        });
    },
  },
  created() {
    this.quotationId = this.lodash.toSafeInteger(this.$route.params.id);
    this.quotationTab = this.$route.query.tab ?? "overview";
  },
  mounted() {
    this.getQuotation();
  },
  computed: {
    ...mapGetters(["contractRecurringScheduleTypeList"]),
    maintenanceType() {
      return this.contractRecurringScheduleTypeList.find(
        (row) => row.value == this.dbQuotation?.contract_type
      );
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
  components: {
    ImageTemplate,
    CommonAttachment,
    DocumentwithName,
    "line-item-view": LineItemViewV2,
    "internal-history-detail": InternalHistoryDetail,
    "internal-invoice": InternalInvoice,
    "internal-visit": InternalVisit,
    "confirm-dialog": Dialog,
    "delete-dialog": Dialog,
    "send-mail": SendMail,
  },
};
</script>
<style lang="scss" scoped>
.quotation-detail-page {
  border-top: solid 8px #f7941e;
  label {
    padding-left: 5px;
    font-weight: 600;
  }
  p {
    padding-left: 5px;
  }
}
</style>
